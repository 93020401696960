.students {
  position: relative;
  z-index: 3;
  padding: rem(0 0 60px);
  margin: rem(-180px 0 0);
  @include media-breakpoint-down(md) {
    margin: 0;
  }
  &__slogan {
    font-size: rem(30px);
    line-height: 1.5;
    color:cl(primary);
  }
  &__text {
    @include media-breakpoint-down(md) {
        text-align: center;
    }
  }
  &__side {
    position: relative;
  }
  &__decor {
    position: absolute;
    width: rem(200px);
    height: auto;
    transform: rotate(70deg);
    left: 95%;
    bottom: 3%;
    filter: drop-shadow(2px 4px 0px white);
    @include media-breakpoint-down(xs) {
      width: rem(100px);
      right: 2rem;
      left:auto;
      bottom: -5rem;
    }
  }
  &__list {
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // grid-template-rows: repeat(4, 1fr);
    grid-gap: rem(20px 27px);
    position: relative;
    z-index: 2;
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(2, 1fr);
    }
    li {
      
    }
    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: rem(161px);
      width:100%;
      padding: rem(30px);
      position: relative;
      transition: transform .3s ease;
      background: cl(white);
      border-radius: rem(15px);
      box-shadow:rem(0 11px 35px cl(black, .16));
      &:hover {
        transform: scale(1.1);
      }
      img {
        position: relative;
        z-index: 2;
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }
  &__pic {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width:100%;
      background: rgba(75, 144, 206, .22);
    }
  }
}