.staff {
  &__title {
    margin: rem(0 0 32px);
    h2 {
      text-transform: uppercase;
      text-align: center;
      font-size: rem(30px);
      font-weight: 700;
      color:cl(primary);
      margin: 0;
    }
  }
  &__list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    --gap-side: 2rem;
    gap:1.875rem var(--gap-side);
    list-style: none;
    margin: rem(0 0 50px);
    @include media-breakpoint-down(xs) {
      --gap-side: 1.875rem;
    }
    &--sm {
      .staff__item {
        @include media-breakpoint-up(xl) {
          --cols: 4;
        }
      }
    }
  }
  &__item {
    text-align: center;
    font-family: $font;
    line-height: 1.2!important;;
    --cols: 3;
    width: calc((100% - var(--gap-side) * (var(--cols) - 1)) / var(--cols));
    &:before {
      opacity: 0;
    }
    @include media-breakpoint-down(xs) {
      --cols: 1;
    }
    p {
      margin: 0;
    }
  }
  &__name {
    color:#1c3f95!important;
    font-size: rem(24px)!important;
    font-weight: normal!important;
    margin: rem(0 0 10px)!important;
  }
  &__email {
    font-size: rem(15px);
    line-height: 1.3;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &__position {
    font-size: rem(18px);
    margin: rem(0 0 10px)!important;
  }
  &__photo {
    display: block;
    width: rem(240px);
    height: rem(240px);
    overflow: hidden;
    border-radius: 50%;
    margin: rem(0 auto 20px);
    box-shadow: rem(0 14px 32px cl(black,.14));
    position: relative;
    z-index: 2;
    &--side {
      width: rem(290px);
      height: rem(400px);
    }
    img {
      display: block;
      width:100%;
      max-width:100%!important;
      height: 100%!important;
      object-fit: cover;
      transition: all .2s linear;
      margin: 0!important;
    }
  }
  &__decor {
    position: absolute;
    width:rem(140px);
    height: auto;
    transform: rotate(-105deg);
    bottom: rem(-90px);
    left: 0;
    z-index: 1;
    @include media-breakpoint-down(xs) {
      left: 1rem;
    }
  }
}