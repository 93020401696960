.congrats {
    padding: rem(100px 0 0);
    position: relative;
    .container {
        position: relative;
        z-index: 4;
    }
    &__text {
        h2 {
            font-size: rem(36px);
            text-transform: uppercase;
            line-height: 1.3;
            color:cl(primary);
            // margin: rem(68px 0);
            font-weight: 700;
            position: relative;
            margin: 0;
            @include media-breakpoint-down(md) {
                order:1;
            }
            &:before {
                content: "";
                position: absolute;
                left:0;
                bottom: 100%;
                width:rem(230px);
                height: rem(74px);
                background: url(../img/svg/stars-top.svg) no-repeat;
                background-size: contain;
            }
            &:after{
                content: "";
                position: absolute;
                left:0;
                top: 100%;
                width:rem(245px);
                height: rem(245px);
                background: url(../img/svg/stars-bottom.svg) no-repeat;
                background-size: contain;
            }
        }
    }
    &__block {
        display: flex;
        align-items: center;
        gap:rem(45px);
        margin: rem(0 0 35px);
        @include media-breakpoint-down(md) {
            justify-content: center;
        }
        @include media-breakpoint-down(xs) {
            flex-direction: column;
        }
    }
    &__item {
        width:rem(318px);
        position: relative;
        &:first-child {
            @include media-breakpoint-down(md) {
                order:2;
            }
        }
        &:last-child {
            @include media-breakpoint-down(md) {
                order:3;
            }
        }
        h3 {
            text-align: center;
            color:cl(white);
            font-size: rem(20px);
            font-weight: 700;
            margin: 0;
            @include media-breakpoint-down(md)  {
                color:cl(primary);
            }
        }
    }
    &__leaf {
        position: absolute;
        width: rem(200px);
        height: auto;
        transform: rotate(70deg);
        bottom: 8%;
        left: 95%;
        z-index: 1;
        @include media-breakpoint-down(xs) {
            width: 8rem;
        }
    }
    &__top {
        padding: rem(22px);
        border-radius: rem(20px);
        box-shadow:rem(0 14px 32px cl(black, .21));
        margin-bottom: rem(45px);
        height: rem(369px);
        position: relative;
        z-index: 2;
        background: cl(white);
    }
    &__image {
        height: rem(280px);
        margin-bottom: rem(13px);
        img {
            display: block;
            width:100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__name {
        background: cl(red);
        color:cl(white);
        text-align: center;
        height: rem(76px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: rem(0 auto -22px);
        position: relative;
        width:calc(100% - 2rem);
        strong {
            font-size: rem(20px);
            font-weight: 700;
            margin-bottom: rem(5px);
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical; 
        }
        span {
            font-size: rem(13px);
            text-transform: uppercase;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical; 
        }
        .left,
        .right {
            position: absolute;
            top:0;
            height: 100%;
            width:2px;
            &:before,
            &:after {
                content: "";
                position: absolute;
                width: 0;
                height: 0;
            }
            &:before {
                top:0;
                border-top: rem(38px solid cl(red));
            }
            &:after {
                bottom: 0;
                border-bottom: rem(38px solid cl(red));
            }
        }
        .left {
            right:calc(100% - 1px);
            &:before,
            &:after {
                right:0;
                border-left: 1rem solid transparent;
            }
        }
        .right {
            left:calc(100% - 1px);
            &:before,
            &:after {
                left:0;
                border-right: 1rem solid transparent;
            }
        }
        &--secondary {
            background: cl(secondary);
            .left,
            .right {
                &:before {
                    border-top-color: cl(secondary);
                }
                &:after {
                    border-bottom-color: cl(secondary);
                }
            }
        }
    }
    &__decor {
        position: absolute;
        left:0;
        bottom: 0;
        width:100%;
        height: rem(520px);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        &:before {
            content: "";
            @extend .full;
            background-color: cl(black, .42);
        }
        &:after {
            content: "";
            @extend .full;
            z-index: 2;
            background: url(../img/bottom-mask-1x.png) no-repeat 50% 0;
            background-size: 100% auto;
            @media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
                background-image:url(../img/bottom-mask-2x.png);
            }
            @include media-breakpoint-down(xs) {
                background-image:url(../img/bottom-mask-mobile-2x.png);
            }
        }
    }
}