.bottom {
  position: relative;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  padding: rem(80px 0 0);
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    background-attachment: fixed;
  }
  &:before {
      content: "";
      @extend .full;
      background-color: rgba(37,58,131,.62);
      z-index: 1;
  }
  &__text {
    padding: rem(0 0 60px);
    position: relative;
    z-index: 4;
    color:cl(white);
    font-size: rem(20px);
    line-height: 1.5;
    text-align: center;
  }
}