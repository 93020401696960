.album {
  &--page {
    padding: rem(50px 0 10px);
    overflow: hidden;
  }
  &__title {
    font-size: rem(40px);
    color:cl(primary);
    margin: rem(0 0 20px);
    font-weight: normal;
    @include media-breakpoint-down(xs) {
      font-size: rem(28px);
    }
  }
  &__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    --gap: 3rem;
    gap:3rem var(--gap);
    @include media-breakpoint-down(md) {
      --gap: 2rem;
    }
    @include media-breakpoint-down(xs) {
      --gap: 1rem;
      gap:2rem var(--gap);
    }
  }
  &__item {
    --cols: 3;
    flex-basis: calc((100% - var(--gap) * (var(--cols) - 1)) / var(--cols));
    @include media-breakpoint-down(xs) {
      --cols: 2;
    }
    h2 {
      font-size: rem(22px)!important;
      letter-spacing: rem(0.2px);
      margin: rem(18px 0 0)!important;
      color:cl(primary)!important;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      @include media-breakpoint-down(xs) {
        font-size: rem(20px);
      }
      a {
        color:cl(primary)!important;
        &:hover {
          text-decoration: none;
          color:cl(secondary)!important;
        }
      }
    }
  }
  &__image {
    display: block;
    overflow: hidden;
    height: rem(270px);
    position: relative;
    border-radius: rem(10px);
    box-shadow: rem(0 10px 20px cl(black,.18));
    @include media-breakpoint-down(xs) {
      height: 10rem;
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
      .album__play {
        background:cl(white);
      }
    }
    img {
      display: block;
      width:100%;
      max-width:100%!important;
      height: 100%!important;
      object-fit: cover;
      transition: all .2s linear;
      margin: 0!important;
    }
  }
  &__count {
    position: absolute;
    top:rem(8px);
    right: rem(8px);
    background:cl(secondary);
    padding: rem(8px 15px);
    color:cl(white);
    font-size: rem(15px);
    line-height: rem(16px);
    border-radius: rem(13rem);
  }
  &__play {
    @extend .pos-center;
    color:cl(secondary);
    font-size: rem(25px);
    transition: all .2s linear;
    box-shadow: rem(0 0 20px cl(black, .5));
    width:rem(60px);
    height: rem(60px);
    border-radius: 50%;
    padding: rem(0 0 0 3px);
    display: flex;
    align-items: center;
    justify-content: center;
    background:cl(white, .8);
    border:2px solid cl(secondary);
  }
  &__date {
    display: block;
    font-size: rem(14px);
    color:cl(gray-derk);
    margin-top: rem(5px);
  }
}