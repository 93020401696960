.static {
  &--page {
    padding: rem(80px 0 0);
  }
  &__article {
    font-size: rem(18px);
    line-height: calc(28 / 18);
    color:cl(text);
    width:100%;
    p {
      margin: rem(0 0 20px);
    }
    h2 {
      font-size: rem(30px);
      color:cl(secondary);
      margin: rem(0 0 20px);
      font-weight: 700;
    }
    h3 {
      font-size: rem(28px);
      color:cl(black);
      margin: rem(0 0 20px);
      font-weight: 700;
    }
    h4 {
      font-size: rem(26px);
      color:cl(black);
      margin: rem(0 0 20px);
      font-weight: 700;
    }
    h5 {
      font-size: rem(22px);
      color:cl(black);
      margin: rem(0 0 20px);
      font-weight: 700;
    }
    h6 {
      font-size: rem(18px);
      color:cl(black);
      margin: rem(0 0 20px);
      font-weight: 700;
    }
    ol, ul {
      margin: rem(0 0 20px);
      padding: rem(0 0 0 20px);
    }
    img {
      display: block;
      margin: 0 auto 2rem;
      max-width:90%;
      height: auto;
    }
    .table {
      border:1px solid #dee2e6;
      @include media-breakpoint-down(xs) {
        table-layout: fixed;
        overflow: hidden;
      }
      tr {
        th {
          border:1px solid #dee2e6;
          background: #dee2e6;
        }
        td {
          border:1px solid #dee2e6;
        }
      }
    }
  }
  &__wrap {
    display: flex;
    gap:rem(50px);
    padding-bottom: rem(70px);
    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }
  &__bottom {
    display: flex;
    justify-content: space-between;
    gap:1rem;
    padding: rem(20px 0 0);
    @include media-breakpoint-down(xs) {
      flex-direction: column-reverse;
    }
  }
}