.news {
  &--section {
    position: relative;
    z-index: 2;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    &:before {
      content: "";
      @extend .full;
      background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 50%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    .container {
      position: relative;
      z-index: 5;
    }
    .btn--white-outline {
      border-width:2px;
      background: cl(black, .3);
      width:rem(370px);
      &:hover,
      &:focus {
        background: cl(black, .6);
      }
    }
  }
  &--page {
    padding: rem(50px 0 10px);
  }
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap:rem(16px);
  }
  &__all {
    text-decoration: underline;
    font-weight: 700;
    text-transform: uppercase;
    &:hover {
      text-decoration: none;
    }
  }
  &__slider {
    padding: rem(0 50px);
    @include media-breakpoint-down(xs) {
      padding: 0 2.5rem;
    }
    .news__item {
      margin: rem(5px 20px 35px);
      box-shadow: rem(0 15px 20px cl(black,.18));
    }
  }
  &__prev,
  &__next {
    @include circle(3.125rem);
    border:2px solid cl(primary);
    background: none;
    color:cl(primary);
    position: absolute;
    top:rem(180px);
    left:0;
    &:hover,
    &:focus {
      outline: none;
      border-color:cl(black);
      color:cl(black);
    }
    @include media-breakpoint-down(xs) {
      @include circle(2.5rem);
    }
  }
  &__next {
    left: auto;
    right:0;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    --gap: 2.5rem;
    gap:3rem var(--gap);
    @include media-breakpoint-down(md) {
      --gap: 2rem;
    }
    @include media-breakpoint-down(xs) {
      gap:2rem var(--gap);
    }
    &--home {
      .news__item {
        --cols: 3;
        @include media-breakpoint-down(xs) {
          --cols: 1;
        }
      }
      .news__image {
        height: rem(192px);
      }
    }
  }
  &__item {
    background: cl(white);
    box-shadow: rem(0 9px 18px cl(black,.18));
    border-radius: rem(20px);
    padding: rem(20px 20px 25px);
    position: relative;
    --cols: 3;
    width: calc((100% - var(--gap) * (var(--cols) - 1)) / var(--cols));
    @include media-breakpoint-down(md) {
      --cols: 2;
    }
    @include media-breakpoint-down(xs) {
      --cols: 1;
    }
  }
  &__image {
    display: block;
    overflow: hidden;
    height: rem(180px);
    border-radius: rem(20px 20px 0 0);
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
    @include media-breakpoint-down(xs) {
      height: 15rem;
    }
    img {
      display: block;
      width:100%;
      height: 100%;
      object-fit: cover;
      transition: all .3s linear;
    }
  }
  &__inner {
    padding: rem(20px 15px 60px);
    font-size: rem(16px);
    line-height: rem(24px);
    color:currentColor;
    overflow: hidden;
    @include media-breakpoint-down(xs) {
      padding-bottom: 0;
    }
    h3 {
      font-size: rem(20px);
      line-height: rem(30px);
      font-weight: 700;
      color:cl(primary);
      margin: rem(0 0 15px);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      height: rem(60px);
      a {
        color:cl(primary);
        &:hover {
          text-decoration: none;
          color:cl(secondary);
        }
      }
    }
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      margin: 0;
      height: rem(72px);
    }
  }
  &__more {
    position: absolute;
    left:rem(34px);
    bottom: rem(-20px);
    width:rem(40px);
    height: rem(40px);
    border-radius: 50%;
    background: cl(secondary);
    border:1px solid cl(secondary);
    color:cl(white);
    font-size: rem(24px);
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover,
    &:focus  {
      text-decoration: none;
      background: cl(white);
      color:cl(secondary);
    }
  }
}