/* banner start */
.banner {
  position: relative;
  background: cl(secondary, 0.5);
  overflow: hidden;
  &--home {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    padding: rem(0 0 25px);
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    @include media-breakpoint-down(lg) {
      
    }
    @include media-breakpoint-down(md) {
      
    }
    @include media-breakpoint-down(xs) {
      // height: 100vh;
    }
    &:before {
      content: "";
      @extend .full;
      background: linear-gradient(to bottom,  rgba(171,197,315,1) 0%,rgba(171,197,315,0) 100%); 
    }
    &:after {
      content: "";
      position: absolute;
      left:0;
      bottom: 0;
      width:100%;
      height: 100%;
      z-index: 2;
      background: url(../img/banner-mask-1x.png) no-repeat 50% 100%;
      background-size: 100% auto;
      @media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
        background-image:url(../img/banner-mask-2x.png);
      }
      @include media-breakpoint-down(xs) {
        background-image:url(../img/banner-mask-mobile-2x.png);
      }
    }
  }
  &--page {
    height: rem(580px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color:white;
    padding: rem(150px 0 0);
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
    @include media-breakpoint-up(lg) {
      background-attachment: fixed;
    }
    @include media-breakpoint-down(md) {
      padding: rem(130px 0 0);
      height: 30rem;
    }
    &:before{
      content: "";
      @extend .full;
      background: cl(secondary, 0.5);
    }
    .container {
      position: relative;
      z-index: 2;
    }
    h1 {
      font-size: rem(60px);
      font-weight: 700;
      margin: 0;
      @include media-breakpoint-down(xs) {
        font-size: rem(40px);
      }
    }
  }
  &__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    &:before {
      content: '';
      @extend .full;
      background: cl(black, 0.5);
      z-index: 2;
    }
    video {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      //@include media-breakpoint-down(xs) {
      //  margin-left: 0;
      //  top: -20px;
      //  height: calc(100% + 20px);
      //}
      //@media #{$min-aspect169} {
      //  height: 400%;
      //  top: -150%;
      //}
      //@media #{$max-aspect169} {
      //  width: 400%;
      //  left: -150%;
      //}
    }
    video[poster]{
      object-fit: cover;
    }
  }
  &__text {
    position: relative;
    width:100%;
    padding: rem(200px 95px 0 280px);
    color:cl(white);
    z-index: 6;
    @include media-breakpoint-down(lg) {
      padding: 9rem 2rem 0 2rem;
    }
    @include media-breakpoint-down(md) {
      text-align: center;
    }
    @include media-breakpoint-down(xs) {
      padding: 11rem 1rem 2rem 1rem;
    }
    p {
      margin: 0;
    }
  }
  &__left {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      padding: rem(75px 0 0);
    }
  }
  &__ttl {
    display: block;
    font-size: rem(70px);
    line-height: 1;
    margin: rem(0 0 25px);
    font-weight: 700;
    text-transform: uppercase;
    text-shadow:rem(4px 4px 16px cl(black,0.19));
    @include media-breakpoint-down(lg)  {
      font-size: rem(60px);
    }
    @include media-breakpoint-down(xs)  {
      font-size: rem(50px);
    }
  }
  &__txt {
    display: block;
    font-size: rem(50px);
    line-height: 1;
    margin: rem(0 0 25px);
  }
  &__links {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap:rem(10px);
    @include media-breakpoint-down(lg)  {
      justify-content: center;
    }
  }
  &__link {
    display: inline-block;
    height:rem(70px);
    width:auto;
    @include media-breakpoint-down(lg)  {
      
    }
    img {
      width:auto;
      height: 100%;
      filter:drop-shadow(rem(3px 10px 32px cl(black,0.3)));
    }
  }
  &__slider {
    border-radius: rem(95px 0 95px 95px);
    overflow: hidden;
    box-shadow:rem(-5px 30px 60px cl(black, .34));
    width:100%;
    margin-bottom: rem(35px);
    @include media-breakpoint-down(xs) {
      border-radius: rem(50px 0 50px 50px);
    }
  }
  &__image {
    // height: rem(626px);
    padding-top: 60%;
    position: relative;
    img {
      position: absolute;
      left:0;
      top:0;
      width:100%;
      height: 100%;
      display: block;
      object-fit: cover;
    }
  }
  &__panel {
    display: flex;
    justify-content: flex-end;
    gap:rem(25px);
    @include media-breakpoint-up(md) {
      padding: rem(0 90px 0 0);
    }
  }
  &__button {
    width:rem(50px);
    height: rem(50px);
    overflow: hidden;
    border-radius: 50%;
    background: cl(secondary);
    color:cl(white);
    border:0;
    font-size: rem(24px);
    box-shadow:rem(0 5px 20px cl(black, .38));
    position: relative;
    z-index: 200;
    &:hover,
    &:focus {
      background: cl(white);
      color:cl(secondary);
    }
  }
  &__leaf {
    position: absolute;
    width: rem(200px);
    height: auto;
    transform: rotate(70deg);
    bottom: 0;
    left: 4%;
    z-index: 101;
    @include media-breakpoint-down(lg) {
      width: 9.5rem;
      left: 33%;
    }
    @include media-breakpoint-down(xs) {
      width: rem(100px);
    }
  }
}
/* banner end */

