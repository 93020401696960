.information {
    padding-top: rem(125px);
    padding-bottom: 23%;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    @include media-breakpoint-up(lg) {
        background-attachment: fixed;
    }
    @include media-breakpoint-down(xs) {
        padding: 4rem 0 5rem;
    }
    &:before {
        content: "";
        @extend .full;
        background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 70%);
        @include media-breakpoint-down(lg) {
            background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 90%);
        }
        @include media-breakpoint-down(md) {
            background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 80%, rgba(255,255,255,0) 90%);
        }
    }
    
    &:after {
        content: "";
        position: absolute;
        left:0;
        bottom: 0;
        width:100%;
        height: 100%;
        z-index: 2;
        background: url(../img/mask-1x.png) no-repeat 50% 100%;
        background-size: 100% auto;
        @media screen and (min-device-pixel-ratio: 2), screen and (-webkit-min-device-pixel-ratio: 2), screen and (min-resolution: 192dpi), screen and (min-resolution: 2dppx) {
            background-image:url(../img/mask-2x.png);
        }
        @include media-breakpoint-down(xs) {
            background-image:url(../img/mask-mobile-2x.png);
        }
    }
    .container {
        position: relative;
        z-index: 3;
    }
    &__text {
        font-size: rem(18px);
        line-height: 1.5;
        ul {
            li {
                list-style: none;
                padding: rem(0 0 0 25px);
                margin: rem(0 0 15px);
                position: relative;
                &::before {
                    content: "";
                    position: absolute;
                    left:0;
                    top:rem(9px);
                    width:rem(9px);
                    height: rem(9px);
                    border-radius: 50%;
                    background-color: cl(secondary);
                }
            }
        }
    }
    &__side {
        position: relative;
        @include media-breakpoint-down(md) {
            margin-bottom: 3rem;
        }
    }
    &__image {
        display: block;
        width:100%;
        height: auto;
        box-shadow:rem(0 3px 51px cl(black, .34));
        border-radius: rem(60px);
        position: relative;
        z-index: 2;
    }
    &__leaf {
      position: absolute;
      width: rem(200px);
      height: auto;
      transform: rotate(-70deg) scaleX(-1);
      right: 95%;
      bottom: 0;
      @include media-breakpoint-down(xs) {
        width: rem(100px);
        right: 65%;
        bottom: -4rem;
      }
    }
}